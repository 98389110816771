import 'App.css';
import Home from 'app/home';
import Starter from 'app/Starter';
// import Landing from 'app/LandingPage/Landing';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'assets/css/bootstrap/main.css'
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { theme } from "theme/lasik";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Reservation from 'app/Reservation/Reservation';
import Location from 'app/Location/Location';
import AppBar from 'app/header/AppBar';
import { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'
var hist = createBrowserHistory();

function App() {
  const [showAppBar, setShowAppBar] = useState(false)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router basename="#" history={hist} >
        {showAppBar && (
          <AppBar showAppBar={showAppBar} />
        )}
        <Switch>
          {/* <Route path="/landing-page" component={LandingPage} />
        <Route path="/profile-page" component={ProfilePage} />*/}
          <Route path="/home/location" component={Location} />
          <Route path="/home/reservation" component={Reservation} />
          <Route path="/home" component={Home} />
          <Route path="/" component={Home} />
          {/* <Route path="/">
            <Starter setShowAppBar={setShowAppBar} />

          </Route> */}

        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
