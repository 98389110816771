import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Slide,
  Toolbar,
  Typography,
  Zoom,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "theme/lasik";
import PhoneIcon from "@material-ui/icons/Phone";
// import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { useState } from "react";
import ReservationForm from "./ReservationForm";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";

const ReservationPage = ({ setReservationFormOpen }) => {
  // const history = useHistory();
  const classes = useStyles();
  const [openDialogPhone, setOpenDialogPhone] = useState(false);
  const handleClickDialogPhone = () => {
    setOpenDialogPhone(true);
  };

  const handleCloseDialogPhone = () => {
    setOpenDialogPhone(false);
  };

  // const handleCallWhatsapp = () => {
  //   window.location.href = "https://wa.me/966553755973";
  // };

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="stretch"
      style={{ padding: 10 }}
    >
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Typography variant="h4" style={{ marginBottom: 5 }}>
          المواعيد
        </Typography>
      </Grid>
      {["الاحد", "الاثنين", "الثلاثاء", "الاربعاء"].map((day, index) => (
        <Grid key={index} item xs={6}>
          <Zoom in={true} style={{ transitionDelay: `${100 * index + 100}ms` }}>
            <Paper style={style} className={classes.gradient}>
              <Typography variant="h4" style={{ marginBottom: 5 }}>
                {day}
              </Typography>
              <Typography>من الساعة 5 مساءً</Typography>
              <Typography>إلى الساعة 8 مساءً</Typography>
            </Paper>
          </Zoom>
        </Grid>
      ))}
      <Grid container spacing={2} style={{ padding: 10 }}>
        <Grid item xs={12}>
          <Zoom in={true} style={{ transitionDelay: `500ms` }}>
            <Button
              fullWidth
              startIcon={<PhoneIcon style={{ marginLeft: 10 }} />}
              color="primary"
              variant="contained"
              size="large"
              style={{ height: 80 }}
              onClick={handleClickDialogPhone}
            >
              الحجز عبر الهاتف
            </Button>
          </Zoom>
          <Dialog
            open={openDialogPhone}
            onClose={handleCloseDialogPhone}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"
              style={{ textAlign: "center" }}
            >
              مستشفيات مغربي
              <br />
              فرع منطقة عسير (خميس مشيط)
              <br />
              الاتصال على الرقم
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={() => {
                    window.location.href = "tel:920018000";
                  }}
                >
                  <br />
                  <PhoneIcon style={{ marginLeft: 10 }} /> 920018000
                  {/* 920018000 - تحويلة 5 */}
                </Button>
                {/* <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: 10, direction: "ltr" }}
                >
                  +966 55 274 1079
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                >
                  0172384823
                </Button> */}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
        {/* <Grid item xs={6}>
          <Zoom in={true} style={{ transitionDelay: `500ms` }}>
            <Button
              fullWidth
              startIcon={<AlternateEmailIcon style={{ marginLeft: 10 }} />}
              color="primary"
              variant="contained"
              size="large"
              style={{ height: 80 }}
              onClick={() => setReservationFormOpen(true)}
            >
              الحجز الكترونياً
            </Button>
          </Zoom>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Zoom in={true} style={{ transitionDelay: `500ms` }}>
            <Button
              fullWidth
              startIcon={<WhatsAppIcon style={{ marginLeft: 10 }} />}
              color="primary"
              variant="contained"
              size="large"
              style={{ height: 80 }}
              onClick={handleCallWhatsapp}
            >
              عبر تطبيق الواتس اب
            </Button>
          </Zoom>
        </Grid> */}
      </Grid>

      {/* <ReservationForm /> */}
    </Grid>
  );
};

const style = {
  //   height: 100,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 15,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction={
        // "up"
        randomSLideTransition[Math.floor(Math.random() * 2)]
      }
      ref={ref}
      {...props}
    />
  );
});
const randomSLideTransition = ["right", "left"];

export default function Reservation() {
  const [reservationFormOpen, setReservationFormOpen] = useState(false);
  const [reservationOpen, setReservationOpen] = useState(true);
  const history = useHistory();
  const handleReservationClose = () => {
    setReservationOpen(false);
    history.push("/home");
  };
  const handleReservationFormClose = () => {
    setReservationFormOpen(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        // open={open}
        onClose={handleReservationClose}
        // onAnimationEnd={() => history.push("./home")}
        TransitionComponent={Transition}
        open={reservationOpen}
      >
        <AppBar position="sticky">
          <Toolbar>
            <Button
              startIcon={<CloseIcon />}
              autoFocus
              color="inherit"
              onClick={handleReservationClose}
            >
              اغلاق
            </Button>
            <Typography
              variant="h6"
              style={{ textAlign: "left", width: "100%" }}
            >
              أ.د.عبدالرحمن العمري
            </Typography>
          </Toolbar>
        </AppBar>

        <ReservationPage
          setReservationFormOpen={setReservationFormOpen}
          // closeReservation={handleReservationClose}
        />
      </Dialog>
      <Dialog
        fullScreen
        // open={open}
        onClose={handleReservationClose}
        TransitionComponent={Transition}
        open={reservationFormOpen}
      >
        <AppBar position="sticky">
          <Toolbar>
            <Button
              startIcon={<CloseIcon />}
              autoFocus
              color="inherit"
              onClick={handleReservationFormClose}
            >
              اغلاق
            </Button>
            <Typography
              variant="h6"
              style={{ textAlign: "left", width: "100%" }}
            >
              أ.د.عبدالرحمن العمري
            </Typography>
          </Toolbar>
        </AppBar>

        <ReservationForm
          // setReservationFormOpen={setReservationFormOpen}
          closeReservation={handleReservationFormClose}
        />
      </Dialog>
    </>
  );
}
